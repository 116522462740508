import VideoBlock from "components/Block/VideoBlock";
import Branding from "components/Branding";
import Memento from "components/Memento";
import FooterMenu from "components/Menu/FooterMenu";
import SecondaryMenu from "components/Menu/SecondaryMenu";
import SocialNetworksMenu from "components/Menu/SocialNetworksMenu";
import Navbar from "components/Navbar";
import PreviewAlert from "components/PreviewAlert";
import SearchForm from "components/Search/SearchForm";
import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useRouter } from "next/router";
import { createRef } from "react";
export default function Layout({
  children,
  globals,
  home = false,
  variant = "institution",
}) {
  const { t } = useTranslation("common");
  const topRef = createRef();
  const mainRef = createRef();
  const footerRef = createRef();
  const brochureRef = createRef();
  const hasVideo = globals?.video || false;
  const { locale, locales, asPath } = useRouter();

  const scrollToFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

   const scrollToBrochure = () => {
    if (footerRef.current) {
      brochureRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <PreviewAlert />

      {home && hasVideo && <VideoBlock data={globals.video} />}

      <header
        ref={topRef}
        className={classNames(
          "top-0 inset-x-0 xl:z-10 flex bg-white shadow-md z-50",
          hasVideo ? "sticky" : "fixed"
        )}
      >
        <div className="flex-initial px-4 py-2">
          <Branding home={home} />
        </div>
        <div className="flex-1">
          <Navbar globals={globals} scrollToFooter={scrollToFooter} scrollToBrochure={scrollToBrochure}  />
        </div>
      </header>

      <main ref={mainRef} className="relative z-0">
        {children}

        <Memento
          form={globals.forms.contact}
          brochure={globals.forms.brochure}
          menu={globals.menus.socialNetworks}
          variant={variant}
          brochureRef={brochureRef}
          path={asPath}
        />
      </main>

      <footer ref={footerRef} className="relative">
        <div className="relative py-16 bg-default-lighter">
          <div className="container flex flex-col gap-4">
            <div className="order-1 xl:order-2 flex flex-wrap flex-col sm:flex-row xl:items-center justify-center gap-8 xl:gpa-16 2xl:gap-32">
              <div className="order-1 flex-initial text-center">
                <Branding small />
              </div>
              <div className="order-3 xl:order-2 flex-[0_0_100%] xl:flex-1 flex flex-col gap-4">
                <SearchForm />
                <div className="flex items-center justify-center xl:justify-end gap-4">
                  <h2 className="text-sm">Nous suivre</h2>
                  <SocialNetworksMenu
                    menu={globals.menus.socialNetworks}
                    small
                  />
                </div>
              </div>
              <div className="order-2 xl:order-3 flex-initial text-center">
                <div className="inline-block w-[200px] md:w-[215px]">
                  <Image
                    src="/images/logo-enseignement-catholique.png"
                    width={215}
                    height={129}
                    layout="responsive"
                    alt="Logo enseignement catholique"
                  />
                </div>
              </div>
            </div>
            <div className="order-2 xl:order-1 flex justify-center mb-8">
              {globals?.menus?.secondary && (
                <SecondaryMenu menu={globals.menus.secondary} />
              )}
            </div>
          </div>
        </div>
        <div className="py-4 bg-default text-white text-sm">
          <div className="container">
            <div className="flex flex-col xl:flex-row md:items-center xl:items-start xl:justify-between gap-4 text-center">
              <a href="https://champagne-creation.fr/" target="blank">
                {t("copyright.text")}
              </a>

              {globals?.menus?.footer && (
                <FooterMenu menu={globals.menus.footer} />
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
