import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Form from "./Form";
import Brochure from "./Form/Brochure";
import SocialNetworksMenu from "./Menu/SocialNetworksMenu";

export default function Memento({ form,brochure, menu, variant = "institution",brochureRef,path="" }) {
  const { t } = useTranslation("common");

  const themes = {
    default: {
      text: "text-default",
      text_alt: "text-white",
      background: "bg-default",
      decoration: "bg-white",
    },
    primary: {
      text: "text-primary",
      text_alt: "text-white",
      background: "bg-primary",
      decoration: "bg-white",
    },
    institution: {
      text: "text-institution",
      text_alt: "text-white",
      background: "bg-institution",
      decoration: "bg-white",
    },
    formation: {
      text: "text-formation",
      text_alt: "text-default-dark",
      background: "bg-formation",
      decoration: "bg-default-dark",
    },
    internship: {
      text: "text-internship",
      text_alt: "text-white",
      background: "bg-internship",
      decoration: "bg-white",
    },
    news: {
      text: "text-news",
      text_alt: "text-white",
      background: "bg-news",
      decoration: "bg-white",
    },
    inscription: {
      text: "text-inscription",
      text_alt: "text-white",
      background: "bg-inscription",
      decoration: "bg-white",
    },
  };

  return (<>

  {path && (path=="/les-formations#brochure" || path=="/les-formations") &&<>
      <div id="brochure"className="h-[100px]" ref={brochureRef}></div>
      <div className="bg-formation relative my-16" >
       <div className="hidden xl:block absolute top-1/2 inset-x-0 h-40 bg-squares-pattern bg-contain bg-repeat-x opacity-20"></div>
        <div className="relative container px-4 xl:px-10 py-10 my-16">
           <h2
            className={classNames(
              "mb-8 xl:mb-10 font-important font-bold text-2xl xl:text-4xl xl:text-center uppercase text-white"
            )}
          >
            Demande de brochure
          </h2>

          <div className="row flex xl:flex-row flex-col ">
            <div className="xl:w-2/12 relative flex justify-center mb-12"><img src="/images/brochure.jpg" className="drop-shadow-xl xl:absolute w-[50%] xl:w-[250px] rotate-[-5deg] top-[-100px]"/></div>
            <div className="xl:w-1/12 xl:flex hidden"> </div>
            <div className="xl:w-9/12">{brochure && <Brochure id={brochure.id} data={brochure.data} variant="institution" brochure={true} />}</div>
          </div>
          
      </div>
    </div></>}
    <section className={classNames("relative", themes[variant].background)}>
      <div className="absolute top-0 inset-x-0 bg-white h-1/2">
        <Image
          src="/images/background-memento.jpg"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          className="opacity-50"
        />
      </div>

      
      <div
        className="hidden xl:block absolute top-1/2 inset-x-0 h-40 bg-squares-pattern bg-contain bg-repeat-x opacity-20 -translate-y-1/2"
        aria-hidden="true"
      />
      <div className="relative container py-16">
        <div className="px-4 xl:px-10 py-10 bg-white mb-16">
          <h2
            className={classNames(
              "mb-8 xl:mb-10 font-important font-bold text-2xl xl:text-3xl xl:text-center uppercase",
              themes[variant].text
            )}
          >
            {t("form.contact.title")}
          </h2>
          {form && <Form id={form.id} data={form.data} variant={variant} />}

        
        </div>
        {menu && (
          <div
            className={classNames(
              "max-w-3xl mx-auto",
              themes[variant].text_alt
            )}
          >
            <h2 className="relative mb-5 font-important font-bold text-lg text-center uppercase">
              <span
                className={classNames(
                  "absolute top-1/2 inset-x-0 h-0.5",
                  themes[variant].decoration
                )}
                aria-hidden="true"
              />
              <span
                className={classNames(
                  "relative inline-block px-2",
                  themes[variant].background
                )}
              >
                {t("socialnetworks.title")}
              </span>
            </h2>

            <SocialNetworksMenu menu={menu} />
          </div>
        )}
      </div>
    </section>
 </> );
}
